.Mint {
    min-height: 600px;
    background-color: #0d1116;
    overflow: visible;
    text-align: center;
  }
  
  .mint-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background:
        repeating-linear-gradient(190deg, rgba(255, 0, 0, 0.5) 40px,
          rgba(255, 153, 0, 0.5) 80px, rgba(255, 255, 0, 0.5) 120px,
          rgba(0, 255, 0, 0.5) 160px, rgba(0, 0, 255, 0.5) 200px,
          rgba(75, 0, 130, 0.5) 240px, rgba(238, 130, 238, 0.5) 280px,
          rgba(255, 0, 0, 0.5) 300px),
        repeating-linear-gradient(-190deg, rgba(255, 0, 0, 0.5) 30px,
          rgba(255, 153, 0, 0.5) 60px, rgba(255, 255, 0, 0.5) 90px,
          rgba(0, 255, 0, 0.5) 120px, rgba(0, 0, 255, 0.5) 150px,
          rgba(75, 0, 130, 0.5) 180px, rgba(238, 130, 238, 0.5) 210px,
          rgba(255, 0, 0, 0.5) 230px),
        repeating-linear-gradient(23deg, red 50px, orange 100px,
          yellow 150px, green 200px, blue 250px,
          indigo 300px, violet 350px, red 370px);
    
  }
  
  .mint-card {
    margin: 20%;
  }


  
  .header {
    margin: 0;
    font-size: 50px;
    font-weight: bold;
  }
  
  .mint-sub-text {
    font-size: 25px;
    color: white;
  }
  
  .gradient-text {
    background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .cta-btn {
    height: 45px;
    border: 0;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .connect-wallet-btn {
    background: -webkit-linear-gradient(left, #60c657, #35aee2);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
  }
  
  .mint-btn {
    background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    margin-right: 15px;
  }
  
  .opensea-btn {
    background-color: rgb(32, 129, 226);
  }
  
  .mint-count {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  
  
  /* KeyFrames */
  @-webkit-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }