:root { 
  --Background: ;
  --Primary: grey;
  --Secondary: linear-gradient(0deg,#001d3d,#718193);
  --Filler: lightblue;
  --text-primary: #fff;
  --text-secondary: rgb(60, 156, 240);

  --font-family1: 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; 
  
  
}

body {
  cursor:context-menu;
  margin: 0;
  font-family: var(--font-family1);
  -webkit-font-smoothing: antialiased;
}

img {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font-family5);
}

h1 {
  color: var(--text-primary);
}

p {
  color: var(--text-primary);
  
}

.Divider {
  background-color: #001d3d;
  min-height: 200px;
}


.trial {
  /* background: linear-gradient(0deg,#4c8284,#fff); */
  background: linear-gradient(0.5turn, #3183a7 40%, #4e9ca6 85%);
  display: flex;
  flex-direction: column;
  /* background:
        repeating-linear-gradient(190deg, rgba(255, 0, 0, 0.5) 40px,
          rgba(255, 153, 0, 0.5) 80px, rgba(255, 255, 0, 0.5) 120px,
          rgba(0, 255, 0, 0.5) 160px, rgba(0, 0, 255, 0.5) 200px,
          rgba(75, 0, 130, 0.5) 240px, rgba(238, 130, 238, 0.5) 280px,
          rgba(255, 0, 0, 0.5) 300px),
        repeating-linear-gradient(-190deg, rgba(255, 0, 0, 0.5) 30px,
          rgba(255, 153, 0, 0.5) 60px, rgba(255, 255, 0, 0.5) 90px,
          rgba(0, 255, 0, 0.5) 120px, rgba(0, 0, 255, 0.5) 150px,
          rgba(75, 0, 130, 0.5) 180px, rgba(238, 130, 238, 0.5) 210px,
          rgba(255, 0, 0, 0.5) 230px),
        repeating-linear-gradient(23deg, red 50px, orange 100px,
          yellow 150px, green 200px, blue 250px,
          indigo 300px, violet 350px, red 370px);
           */
}