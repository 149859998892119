/* Position the image container (needed to position the left and right arrows) */
.Gallery-container {
  background-color: #fff;
  padding: 0px 10vw;
  margin: 40px 0px;
}

.Gallery {
  
 
  // border: solid white;
  position: relative;
  
  padding: 50px 100px;
  // height: 100vh;
  overflow: hidden;
  justify-content: center;
  
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .fit {
    height: 500px;
  }

  
  .primarySlide {
    display: block;
    max-width: 100%;
    object-fit: contain;
  }

  .grid {
    height: 200px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
  }

  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
    cursor: pointer;
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }


  /* Container for image text */
  .caption-container {
    text-align: center;
    background-color: #fff;
    padding: 2px 16px;
    
    h1 {
      color: black
    }
  }

  /* Add a transparency effect for thumnbail images */
  .demo {
    opacity: 0.6;
  }

  .active,
  .demo:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .Gallery {
    padding: 40px 10vw;
  }
}