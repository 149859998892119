@import "footer";
@import "faq";
@import "gallery";


@use "sass:math";
$Background: grey;
$font: "roboto";

@mixin flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin margins {
  margin-left: 16vw;
  margin-right: 16vw;
  margin-bottom: 20px;
  border-radius: 30px;
}

@mixin border {
  border: solid 2px white;
  border-radius: 20px;
  padding: 20px;
}
@mixin padding {
  padding: 0px 16vw 30px 16vw;
}

// ------------- NAVBAR -----------------
.navbar {
  // background: linear-gradient(0deg, #367dafff, rgba(171, 191, 200, 1));
  background: #387eaf;
  height: 80px;
  @include flexColumn;
  
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  .logo {
    height: 46px;
    width: auto;  
  }

  &-container {
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: center;
    align-items: center;

  }
  &-logo {
    display: flex;
    color: #fff;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }

  .nav {
    &-menu {
      display: grid;
      grid-template-columns: repeat(5, auto);
      list-style: none;
      align-items: center;
      width: 60vw;
      justify-content: flex-end;
      align-items: start;
      margin-right: 2rem;
    }

    &-item {
      height: 80px;
    }

    &-links {
      color: #fff;
      display: flex;
      text-decoration: none;
      padding: 0.5rem 1rem;
      height: 100%;
      transition: 0.4s;
      align-items: center;
      &:hover {
        border-bottom: 4px solid #fff;
        transition: all 0.2s ease-out;
      }
    }

    &-links-mobile {
      display: none;
    }
  }

  .fa-bars {
    color: #fff;
  }

  .menu-icon {
    display: none;
    padding: 1.5rem;
  }

  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .nav-menu {
      @include flexColumn;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }

    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }

    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      /* transform: translate(-10%, 10%); */
      font-size: 1.8rem;
      cursor: pointer;
    }

    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
}

// -------------- Hero section -----------------

.hero-container {
  background: url("../../../public/images/BG2.jpg") center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  @include flexColumn;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
  object-fit: contain;

  h1 {
    color: #fff;
    font: 100px $font;
    margin-top: -100px;
    text-shadow: 10px 10px 30px black;
  }

  p {
    text-shadow: 10px 10px 30px black;
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    /* color: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.5)) */
  }

  .hero-btns {
    margin-top: 32px;
    border: solid white 2px;
  }

  .fa-play-circle {
    margin-left: 4px;
  }

  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }

  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }

    .hero-container > p {
      font-size: 30px;
    }

    .btn-mobile {
      display: block;
      text-decoration: none;
    }

    .btn {
      width: 100%;
    }
  }

  .wrapper {
    height: 100vh;
    width: 100%;
    @include flexColumn;
    background-color: var(--Background);
  }

  .form-wrapper {
    width: 400px;
    @include flexColumn;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px #555;
    background-color: #ffffff;
  }

  .login-input {
    padding: 5% 12%;
    margin: 2%;
    /* margin-bottom: 5%; */
    border-radius: 5px;
    outline: none;
    border: 1px solid #cfcfcf;

    &::placeholder {
      font-size: 1.2em;
      font-weight: lighter;
      color: #999;
    }
  }

  .login-input.error {
    border: 1px solid red;
  }
}

// ---------------- Project section ---------------

.project {
  @include flexColumn;
  min-height: 80vh;
  max-width: 100vw;
  margin: 10vh 0px 0px 0px;
  background: no-repeat url(../../../public/images/Palm\ Trees\ 1\ Trans.png);
  // background-size: cover;

  &-buttons {
    @include margins;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 30px 12px;
    
    max-width: 80vw;
    gap: 2rem;
    justify-content: center;
  }
  &-button {
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    background: transparent;
    align-self: center;
    justify-self: center;
    background-color: rgba(255,255,255, 0.15);
    padding: 12px 26px;
    font-size: 20px;
    border: 1px solid #fff;

    &:hover {
      transition: all 0.3s ease-out;
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

  &-container {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // background: rgba(255, 255, 255, 0.1);
    background-color: #00000045;
    display: flex;
    @include border;
    margin-left: 16vw;
    margin-right: 16vw;
    margin-bottom: 100px;
    border-radius: 10px;
  }

  &-text-container {
    /* border-color: #fff; */
    /* border: 5px; */

    display: grid;
    min-width: 35%;
    grid-template-rows: 70% 10% 20%;
    // background-color: #00000065;
    border-radius: 10px;
  }

  &-text {
    padding: 20px;
    flex: 5;
    
    h1 {
      text-shadow: 6px 6px 8px #0000007c;
    }
    p {
      font-size: larger;
      font-weight: bold;
      // text-shadow: 4px 4px 20px #242222;
    }
  }

  &-mint-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
  }

  &-mint {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-mint-button {
    cursor: pointer;
    color: white;
    background-color: #6e9fb3;
    font-size: larger;
    border-style: solid;
    border-radius: 20px;
    padding: 5px 15px;
    margin: 4% 14% 4% 14%;
    min-width: 50px;
    box-shadow: rgb(0, 0, 0);
    &:hover {
      background-color: #fff;
      transition: 0.8s;
      color: #000;
    }
  }
  &-photos-container {
    border-radius: 25px;
    margin: 2vw;
    display: grid;
    grid-template-rows: 66% auto;
    // width: 600px;
  }

  &-photos-main {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 400px;
    margin: 0.5vw;
  }

  &-photos {
    display: flex;
    flex-direction: row;
    height: 200px;
    gap: 0.5vw;
    margin: 0.5vw;
    margin-bottom: 0px;
  }

  &-photo {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .fas-holder {
    padding: 20px;
    border-radius: 30px;
    &:hover {
      transition: 0.4s ease-in-out;
      opacity: 0.7;
      background-color: lightgrey;
      transform: scale(1.1);
    }
    .fas {
      width: 16px;
      height: 16px;
    }
  }

  .hidden {
    display: none;
  }

  @media screen and (max-width: 1280px) {
    .project-photos {
      display: none;
    }
    .project-photos-container {
      grid-template-columns: 100%;
      max-width: 100%;
    }
    .project-text-container {
      max-width: fit-content;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 960px) {
    .project-photos {
      display: none;
    }

    .project-container {
      flex-direction: column;
      width: 100%;
      margin: 0px 10vw;
      padding: 0px 20vw;
    }
    .project-text-container {
      max-width: fit-content;
    }
    .project-buttons {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 600px) {
    .project-photos {
      display: none;
      max-width: 100vw;
      &-main {
        width: 100%;
      }
    }

    .project-buttons {
      grid-template-columns: repeat(2, 1fr);
    }
    .project {
      margin: 0px;
    }
    .project-container {
      width: 90%;
      flex-direction: column;
      padding: 0px;
    }
  }
}
// ------------- text section --------------
.text {
  @include flexColumn;
  margin: 10vh 0px 0px 0px;
  // background: #fff;
  &-container {
    @include border;
    @include margins;
    // background: #5b8f7f;
    margin-bottom: 5vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    border-width: 5px;
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      li {
        padding: 20px;
        list-style-type: none;
        h1 {
          padding-bottom: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .text-container-grid {
    grid-template-columns: auto;
  }
}
// ------------- Road map -------------------
.Roadmap {
  @include flexColumn;
  padding: 0rem 8rem;
  min-height: 100vh;

  .stripe {
    @include flexColumn;
    background-color: #5b8f7f;
    height: 100px;
    position: relative;
  }
  h1 {
    display: flex;
    text-align: center;
    justify-content: center;

    overflow: visible;
  }
  .Roadmap-map {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: grid;
    width: 100%;
    grid-template-columns: 70% 30%;
    margin: 1vw;

    border: solid 2px white;
    border-radius: 10px;
  }

  .Roadmap-text {
    padding: 1rem;
    border-radius: 30px 0px 0px 30px;
    border: 10px;
  }

  .Roadmap-image {
    max-height: 300px;
    border-radius: 0px 30px 30px 0px;
  }

  .Roadmap-boundary {
    padding: 2px;
    border: 10px;
    border-color: #fff;
    border-radius: 20px;
  }

  .Roadmap-map-flipped {
    border: solid white 2px;
    display: grid;
    border-radius: 10px;
    grid-template-columns: 30% 70%;
    margin: 1vw;
    box-shadow: 10px;
  }

  .Roadmap-image-flipped {
    max-width: 100%;
    max-height: 100%;
  }
  .Roadmap-text-flipped {
    height: 100%;
    padding: 1rem;
    border: 10px;
  }

  .card-content-wrapper {
    object-fit: fill;
  }
}

@media screen and (max-width: 700px) {
  .Roadmap {
    padding: 10vw;
  }
  .Roadmap-image {
    display: none;
  }
  .Roadmap-map {
    grid-template-columns: 100%;
  }
}
// ------------- Scroller --------------------

.scroller {
  display: flex;
  margin: 0px;
  padding: 1vw;
  /* background-color: lightslategrey; */
  background: white; 
  justify-content: center;
  align-content: center;
  z-index: 1;

  .scroller-container {
    @include flexColumn;
    position: relative;
    min-height: 500px;
    padding: 8vh 16vw;
    background: var(--Background);
  }

  .scroller-image {
    width: 60vw;
    height: 60vh;
    border-radius: 10px;
  }

  .right-arrow {
    position: absolute;
    top: 50%;
    right: 10vw;
    font-size: 3rem;
    color: #fff;
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }

  .left-arrow {
    position: absolute;
    top: 50%;
    left: 10vw;
    font-size: 3rem;
    color: #fff;
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }

  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }

  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }

  @media screen and (max-width: 700px) {
    .scroller-image {
      width: 300px;
      height: 200px;
    }
    .scroller-container {
      padding: 0px;
    }
  }
}
// ------------- Details ---------------------


.iframe-container {
  border: 0;
  width: 98.8vw;
  height: 50vh;
}
iframe {
  width: 100%;
  height: 50vh;
  
}

.image-container {
  max-width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  @include padding;
  padding-top: 20px;
  background: #f1a66f;

  &-container {
    border: 2px solid white;
    border-radius: 10px;
    display: grid;
    grid-template-areas: "a b" "c c";
    justify-content: center;
    max-width: 1120px;
    min-height: 100px;
    width: 90%;
    margin: 0 auto;
  }


  .wide {
    grid-area: c;
  }

  &-item {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    margin: 1rem;
    border-radius: 20px;
    background: #fff;
    margin-bottom: 24px;
  }

  &-item-bottom {
    display: flex;

    flex-direction: column;
    padding: 2vw 2vw;
    margin: 1rem;
    border-radius: 20px;
    background: #fff;
    margin-bottom: 24px;
  }

  &-text {
    color: black;
  }

  @media only screen and (min-width: 1024px) {
    &-items {
      display: flex;
    }
  }

  @media only screen and (max-width: 1024px) {
    &-item {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1040px) {
  .details-container {
    @include flexColumn;
  }
}

//----------- About ------------------
.about {
  @include margins;
  @include flexColumn;
  padding: 4rem;
  background: var(--Background);
  min-height: 70vh;

  .about-container {
    display: flex;
    gap: 20px;
    border-radius: 10px;
    padding: 24px 12px;
  }
  .about-person-container {
    @include border;
    @include flexColumn;
    &:hover {
      opacity: 0.5;
      transition: ease-in-out 0.5s;
      transform: scale(0.8);
      @include border;
    }
  }
  .about-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    max-height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .about-text {
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .about {
    margin: 0px;
  }
  .about-container {
    flex-direction: column;
  }
}
