.wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #022e64;
}

.form-wrapper {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px #555;
    background-color: #ffffff;
}

/* 
form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
} */
/* 
.form-wrapper h3 {
    text-align: center;
    width: 100%;
    color: #111;
    font-weight: lighter;
} */
/* 
label {
    font-size: 0.8em;
    margin-bottom: 0.25em;
    color: #222;
    font-weight: lighter;
} */


.login-input {
    padding: 5% 12%;
    margin: 2%;
    /* margin-bottom: 5%; */
    border-radius: 5px;
    outline: none;
    border: 1px solid #cfcfcf;
   
}


.login-input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #999;
}

.login-input.error {
    border: 1px solid red;
}